import {
  TrioBotDifficulty,
  TrioBotDifficultySetting,
  TrioPoints,
  TrioTimers,
} from '@common/games/Trio/types';
import { GameName } from '@common/types/games';

type ConfigValueSchema =
  | string
  | number
  | boolean
  | ReadonlyArray<ConfigValueSchema>
  | { [key: string]: ConfigValueSchema };

type ConfigKeySchema = `${string}_${string}`;
type ConfigSchema = Readonly<Record<ConfigKeySchema, ConfigValueSchema>>;

export enum GameStatus {
  Hidden = 0,
  Visible = 1,
  ComingSoon = 2,
  Beta = 3,
  NonDiscordOnly = 4,
}

export const defaults = {
  /**
   * Game statuses
   */
  root_gameStatuses: {
    Trio: GameStatus.Visible,
    KitchenSink: GameStatus.Hidden,
    Avocado: GameStatus.Visible,
    Durian: GameStatus.Visible,
    Guava: GameStatus.Visible,
    Farkleberry: GameStatus.Hidden,
    McMind: GameStatus.Hidden,
    Clementine: GameStatus.Hidden,
  } as Record<string, GameStatus>,

  /**
   * Game rankings
   */
  root_gameNamesSorted_Mobile: [
    'Trio',
    'KitchenSink',
    'Avocado',
    'Durian',
    'Guava',
    'Farkleberry',
    'McMind',
    'Clementine',
  ] as GameName[],

  root_gameNamesSorted_Desktop: [
    'Trio',
    'KitchenSink',
    'Guava',
    'Avocado',
    'Durian',
    'Farkleberry',
    'McMind',
    'Clementine',
  ] as GameName[],

  /**
   * Root
   */
  root_JotaiDevTools: false,
  root_ShowStartTimer: true,
  root_bypassMinPlayersCheck: false,
  root_keepToastsOnScreen: false,
  root_animationCompletionTimeout: 10,
  root_animationCompletionCheckInterval: 0.1,
  root_skipFramerMotionAnimations: false,
  root_skipCoverSheet: false,
  root_hostsCanBeMarkedAsHavingLeft: true,
  root_disablePublicRooms: false,

  discord_skipGuildMemberFetch: false,

  /**
   * Network
   */
  net_heartbeatIntervalSeconds: 4,
  net_secondsAfterLastSeenToDisconnectWhileInLobby: 5,
  net_secondsAfterLastSeenToDisconnectWhileInGame: 8,
  net_secondsAfterHeartbeatExpirationToRemovePlayer: 45,
  net_secondsAfterNonHeartbeatDisconnectionToRemovePlayer: 45,
  net_allowPlayerRemovalDuringGameDueToSocketDisconnection: false,

  /**
   * Chat
   */
  chat_cooldownMessageLimit: 4,
  chat_cooldownTimeWindow: 11,
  chat_characterLimit: 50,

  /**
   * Emote
   */
  emote_emoteCooldownSeconds: 1.5,

  /**
   * Avocado
   */
  avocado_pointsForCorrectGuess: 50,
  avocado_pointsForJudgesChoice: 100,
  avocado_pointsToWin: 500,

  avocado_judgeChoosePromptTime: 15,
  avocado_submissionTime: 45,
  avocado_judgeReadEachAnswerTimer: 5,
  avocado_submissionTimeOnePlayer: 5,
  avocado_choiceTime: 20,
  avocado_animationSpeedMultiplier: 1,
  avocado_outcomeLingerTime: 10,
  avocado_scoreboardTime: 10,

  avocado_submissionMinCharsToShowLimit: 50,
  avocado_submissionCharacterLimit: 120,

  /**
   * Durian
   */
  durian_configureTime: 20,
  durian_chooseCategoryTime: 20,
  durian_hintTime: 60,
  durian_timerDisplayThreshForHinting: 45,
  durian_delayBetweenRevealForHints: 2,
  durian_delayBetweenAvatarAndHintForHints: 0.5,
  durian_voteTime: 45,
  durian_timerDisplayThreshForVoting: 30,
  durian_spyRedemptionTime: 21,
  durian_delayBetweenRevealForRedemption: 0.1,
  durian_delayBetweenAvatarAndHintForRedemption: 0,
  durian_endScreenTime: 20,

  /**
   * Farkleberry
   */
  farkleberry_roundTime: 45,
  farkleberry_totalRounds: 3,

  /**
   * Guava
   */
  guava_numStartingLifelines: 2,
  guava_totalRounds: 8,
  guava_pickingWordTime: 20,
  guava_guessingTime: 20,
  guava_revealTime: 15,
  guava_guessingTimeOnePlayer: 5,
  guava_isGuessingTimed: true,
  guava_delayBetweenWordPickerScreenAndGameBoard: 3,
  guava_competitionPoints: 50,

  /**
   * McMind
   */
  mcmind_lives: 3,
  mcmind_isPressAndHoldEnabled: true,

  /**
   * Trio
   */
  trio_boardWidth: 3,
  trio_boardHeight: 3,
  trio_handSize: 2,
  trio_totalTurns: 20,

  trio_bot: {
    difficulty: {
      easy: {
        beatsTile: 0,
        tiesTile: 0,

        threeAvailableSlots: 0,
        sixAvailableSlots: 0,
        nineAvailableSlots: 0,

        chaseThreeOfAKind: 1,
        chaseOneOfEach: 1,
        chaseSingle: 1,

        blockThreeOfAKind: 0,
        blockOneOfEach: 0,
      },
      hard: {
        beatsTile: 2,
        tiesTile: 1,

        threeAvailableSlots: 0,
        sixAvailableSlots: 0,
        nineAvailableSlots: 1,

        chaseThreeOfAKind: 4,
        chaseOneOfEach: 3,
        chaseSingle: 2,

        blockThreeOfAKind: 2,
        blockOneOfEach: 1,
      },
    } satisfies Record<TrioBotDifficulty, TrioBotDifficultySetting>,
  },

  trio_points: {
    goal: 6,
    threeOfAKind: 3,
    oneOfEach: 2,
    basic: 1,
  } satisfies TrioPoints,

  trio_timers: {
    PlaceTiles: 12,
    RevealPlacements: 0.1,
    ResolveCombatZone: 0.3,
    Battle: 6,
    BattleReveal: 1,
    Scoring: 0.1,
    GameOverTransition: 2,
    GameOver: 60,
  } satisfies TrioTimers,

  /**
   * Testing
   */
  testing_muteMusic: false,
  testing_muteSoundEffects: false,
  testing_alwaysUseStaticAvatars: false,

  /**
   * ScoreScreen
   */
  ScoreScreen_showBottomRowDelay: 1.5,
} as const satisfies ConfigSchema;

/**
 * This config type will always reflect DEFAULT config values.
 * Runtime values MAY differ, if they have been overriden due to:
 * 1) A /config POST request, which overrides the config for a specific room
 * 2) A local-overrides.json file, which overrides the config for all rooms (when running locally)
 */
export type Config = typeof defaults;
